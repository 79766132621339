import { FC, useContext, useEffect } from 'react'
import { observer } from "mobx-react-lite"
import { Context } from '.'
import { useLocation, useParams } from 'react-router-dom'
import useWindowDimensions from './core/hooks/useWindowDimensions'
import AppRouter from './components/AppRouter'

import { useGeolocated } from "react-geolocated"

import './styles/App.scss';
import Footer from './components/Footer'
import Header from './components/Header'
import { useFetching } from './core/hooks/useFetching'
import { ApiService } from './core/api/api'
import {getCountryCode, isNullOrUndefined, shuffle, translit} from './core/utils/utils'
import OptionsModal from './pages/_shared/OptionsModal/OptionsModal'
import {StoreOrder} from './core/models/store/order'
import {YMaps} from '@pbe/react-yandex-maps'
import {Translation} from './core/models/Translate'
import {SNG_OR_WRLD, StorageKeys} from './core/utils/defaults'
import {IntlProvider} from 'react-intl'
import {LOCALES} from './core/i18n/locales'

declare const ymaps: any

const App: FC = () => {

  const { store, shopStore, studioStore, adminStore } = useContext(Context)

  const location = useLocation()
  const params: any = useParams()
  
  const { coords, isGeolocationAvailable, isGeolocationEnabled } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
  })
  
  const { width, height } = useWindowDimensions()

  const [fetchSettings] = useFetching(async () => {
    const res = await ApiService.core.getSettings()

    let techworks = res.data.settings.find((v: any) => v.name == "techworks")
    let tr = res.data.settings.find((v: any) => v.name == "translations")
    
    if (techworks != undefined) {
      store.setTechworksState(techworks.data.state)
      store.setTechworksSetting(techworks)
    }

    let lst: any = {}, lst2: any[] = [], lst3: any[] = [];
    tr.data.forEach((lang: Translation) => {
      let ccode = getCountryCode(lang.nameEn);
      lst2.push({ name: lang.name, nameEn: lang.nameEn, code: ccode })

      lst[ccode] = {};
      lang.items.forEach((item) => {
        if (ccode == "ru-RU") {
          lst[ccode][item.name] = item.value;
        } else {
          if (!isNullOrUndefined(item.value_en)) {
            lst[ccode][item.name] = item.value_en;
          } else {
            lst[ccode][item.name] = item.value;
          }
        }
      })

      lst3.push({...lang})
    })

    const restoreLocale = localStorage.getItem(StorageKeys.LOCALE)
    if (restoreLocale != null) {
      store.setLocale(restoreLocale)
    } else {
      store.setLocale(lst2[0].code)
      localStorage.setItem(StorageKeys.LOCALE, lst2[0].code)
    }

    store.setTransAll(lst3)
    store.setTranslations(lst)
    store.setLocales(lst2)

    store.setIsSettingsPreloaded(true)
    setTimeout(() => {
      store.setIsSettingsLoaded(true)
    }, 2000)
  })

  const [fetchAccount] = useFetching(async () => {
    const authed = store.checkAuth()

    if (authed) {
      try {
        const res = await ApiService.users.getAccount()
        const resOrders = await ApiService.studios.getMyOrders()
      
        let ordrs: any[] = resOrders.data.orders.sort((a: StoreOrder, b: StoreOrder) => {
          return a.status > b.status ? 1 : (a.status < b.status ? -1 : 0)
        })
        studioStore.setOrders(ordrs)

        // console.log("account", res.data, resOrders.data)
        if (res.data.state == 1) {
          store.setupAuth(res.data.user.token, res.data.user)
        }
      } catch (err) {
        console.log("err", err)
      }
    }
  })

  const [fetchCountries] = useFetching(async () => {
    const res = await ApiService.studios.getCountries()

    let ctrs: any[] = res.data.countries.sort((a: any, b: any) => {
      return a.order > b.order ? 1 : (a.order < b.order ? -1 : 0)
    })

    // if (SNG_OR_WRLD == "sng") {
    //   ctrs = ctrs.filter(v => v.is_sng == true)
    // } else {
    //   ctrs = ctrs.filter(v => v.is_sng == false)
    // }

    studioStore.setCountries(ctrs)

    const restoreCtr = localStorage.getItem(StorageKeys.COUNTRY)
    if (restoreCtr != null) {
      studioStore.setSelCountry(JSON.parse(restoreCtr))
    } else {
      studioStore.setSelCountry(ctrs[0])
    }

    fetchCities()
  })

  const [fetchCities] = useFetching(async () => {
    const citiesRes = await ApiService.studios.getCities()

    let cities: any[] = citiesRes.data.cities.sort((a: any, b: any) => {
      return a.order > b.order ? 1 : (a.order < b.order ? -1 : 0)
    })

    studioStore.setAllCities([...cities])

    if (studioStore.selCountry != null) {
      cities = cities.filter(v => v.country_id == studioStore.selCountry.id)
    }

    cities = cities.map(v => {
      return {...v, code: translit(v.name.toLowerCase())}
    })

    let cts: any[] = cities.map(v => {
      return {code: translit(v.name.toLowerCase()), name: v.name, id: v.id}
    })

    // console.log(JSON.stringify(cts))

    studioStore.setCities(cities)

    // console.log("APPPPPP !!!!! city", params, studioStore.selectedCity)
    if (studioStore.selectedCity == null) {
      if (isNullOrUndefined(params.city)) {
        if (isGeolocationEnabled && coords != undefined) {
          console.log("Geolocation enabled! Search user city...", coords)
    
          const geocoder = ymaps.geocode([coords.latitude, coords.longitude])
    
          geocoder.then((res: any) => {
            // console.log(res)
    
            const cityChilds = res.geoObjects._collectionComponent._baseArrayComponent._children  
            
            // console.log(cityChilds)
    
            if (!isNullOrUndefined(cityChilds) && cityChilds.length > 0) {
              const cityContain = cityChilds[0].properties._data.text
              let exists: any | null = null
              cities.forEach((city) => {
                // console.log(cityContain.toLowerCase(), city.name.toLowerCase())
                if (cityContain.toLowerCase().includes(city.name.toLowerCase())) {
                  exists = city
                }
              })
    
              // console.log(exists)
    
              // if (exists == null) {
              //   studioStore.setSelectedCity(cities[0])
              //   studioStore.setSelectedCityName(cities[0].name)
              // } else {
              //   studioStore.setSelectedCity(exists)
              //   studioStore.setSelectedCityName(exists.name)
              // }
            }
          }, (err: any) => {
            console.log(err)
          })
        } else {
          // studioStore.setSelectedCity(cities[0])
          // studioStore.setSelectedCityName(cities[0].name)
        }
      } else {
        const exCity = cities.find(v => v.code == params.city)
        if (exCity != undefined) {
          // studioStore.setSelectedCityName(exCity.name)
          // studioStore.setSelectedCity(exCity)
        }
      }
    }
  })

  const [fetchStudios] = useFetching(async () => {
    const res = await ApiService.studios.getStudios({
      country_id: studioStore.selCountry == null ? 1 : studioStore.selCountry.id,
      city_id: studioStore.selectedCity == null ? 0 : studioStore.selectedCity.id,
      page: studioStore.pageIndex,
      limit: studioStore.pageLimit,
      search: studioStore.search,
      category: studioStore.category,
      order: studioStore.order,
      order_by: studioStore.orderBy,
      status: studioStore.status,
      date: studioStore.searchDate,
      time: studioStore.searchTime,
      service: studioStore.searchService
    })

    let studiosLst: any[] = res.data.studios

    let priority: any[] = studiosLst.filter(v => v.priority != 0)
    let unfilled: any[] = studiosLst.filter(v => v.images == "" || v.phone == "" || v.address == "")
    let all: any[] = studiosLst.filter(v => v.priority == 0 && v.images != "" && v.phone != "" && v.address != "")

    all = shuffle(all)
    all = shuffle(all)

    studiosLst = [...priority, ...all, ...unfilled]
    
    studiosLst = studiosLst.map(v => {
      let city = studioStore.cities.find(ct => ct.id == v.city_id)
      return {...v, city, imgs: v.images.split(";"), sel_image: v.images != "" ? v.images.split(";")[0] : ""}
    })

    studioStore.setTotalStudios(res.data.total)
    studioStore.setStudios(studiosLst)
  })

  const [fetchCats] = useFetching(async () => {
    const res = await ApiService.studios.getCats()
    let cts: any[] = res.data.cats

    cts = cts.sort((a: any, b: any) => {
      return a.order > b.order ? 1 : (a.order < b.order ? -1 : 0)
    })
    
    studioStore.setAllCats(cts)
  })

  const handleLeftPosition = () => {
    const cardwrap = document.getElementById("cardwrap")

    if (cardwrap != null) {
      // console.log(cardwrap.offsetLeft)
      if (width > 960) {
        shopStore.setLeftPos(`${cardwrap.offsetLeft - 1}px`)
      } else if (width > 768) {
        shopStore.setLeftPos(`${(cardwrap.clientWidth / 2) - 190}px`)
      } else {
        shopStore.setLeftPos(``)
      }
    }
  }

  useEffect(() => {
    handleLeftPosition()
  }, [width])

  useEffect(() => {
    // console.log(isGeolocationAvailable)
    // console.log(isGeolocationEnabled)
    // console.log(coords)
    if (coords != undefined) {
      setTimeout(() => {
        fetchCountries()
      }, 500)
    }
  }, [coords])

  useEffect(() => {
    if (width <= 768) {
      store.setIsMobile(true)
    } else {
      store.setIsMobile(false)
    }
  }, [width, height])

  useEffect(() => {
    if (width <= 768) {
      store.setIsMobile(true);
    } else {
      store.setIsMobile(false);
    }

    handleLeftPosition()

    setTimeout(() => {
      handleLeftPosition()
    }, 1000)

    fetchCountries()

    fetchCats()
    fetchSettings()
    fetchAccount()
    fetchStudios()
  }, [])

  return (
    <>
      <div className="xfader" style={{opacity: store.isSettingsLoaded ? "1" : "0"}}>

        {store.isSettingsPreloaded ? <>
        
          {store.techworksState && !location.pathname.includes("admin") && (!store.isAuth || (store.isAuth && store.userData.role != "ADMIN")) ? <>
            <div className={"placeholder"}>
              <div className={"ph_wrap"}>
                {/* TODO: Технические работы на сайте */}
              </div>
            </div>
          </> : (location.pathname.includes("/admin") || location.pathname.includes("/timetable") || location.pathname.includes("/booking") ? <>
            <IntlProvider messages={store.translations[store.locale]} locale={store.locale} defaultLocale={LOCALES.RUSSIAN}>
              <AppRouter />
            </IntlProvider>
          </> : <>
            <IntlProvider messages={store.translations[store.locale]} locale={store.locale} defaultLocale={LOCALES.RUSSIAN}>
              <YMaps query={{lang: "ru_RU", apikey: "ddba014a-2dcd-4f60-9c58-4bd8d9d0c0e4"}}>
                <Header />
                <AppRouter />
                <Footer />
              </YMaps>
              <OptionsModal
                height="auto"
                openned={studioStore.isCityVisible}
                title="Выберите город"
                onClose={() => studioStore.setIsCityVisible(false)}
                done="Выбрать"
                onDone={() => {
                  studioStore.setIsCityVisible(false)
                }}>
                <div className="cities_selecting">
                  {studioStore.cities.map(city => <div className={"cs_item" + (studioStore.selectedCityName == city.name ? " selected" : "")} onClick={() => {
                      studioStore.setSelectedCity(city)
                      studioStore.setSelectedCityName(city.name)
                    }}>
                    <div className="cs_item__name">{city.name}</div>
                    <div className="cs_item__check"><span><i className="fas fa-check"></i></span></div>
                  </div>)}
                </div>
              </OptionsModal>
            </IntlProvider>
          </>)}
        
        </> : <></>}

      </div>
    </>
  )
}

export default observer(App)

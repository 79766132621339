export enum HomeMenuPage {
  NONE = "",
  FEATURES = "features",
  PRODS = "products",
  PRODS_O = "products_one",
  PRODS_TW = "products_two",
  PRODS_T = "products_three",
  USEFULLS = "usefulls",
  EXPIRIENCE = "expirience"
}

export enum ControllerName {
  USERS = "/users",
  PROJECTS = "/projects",
  CORE = "/core",
  CRM = "/crm",
  STORE = "/store",
  STUDIOS = "/studios",
  BLOG = "/articles",
  BRANDS = "/brands"
}

// export const API_URL = "http://localhost:4249";
export const API_URL = "https://api.podcastbery.ru";
// export const API_URL = "https://download.topgunadsnetwork.ru";

export const SNG_OR_WRLD: "sng" | "world" = "sng"

export type SliderToggleDirection = "next" | "prev";
export enum SliderToggleDir {
  NEXT = "next",
  PREV = "prev"
}

export enum StorageKeys {
  AUTHED = "nx_fermstory_authed",
  USER = "nx_fermstory_user",
  TOKEN = "nx_fermstory_token",
  GOOGLE_TOKEN = "nx_fermstory_google_token",

  ADMIN_CRM_VIEW_TYPE = "nx_fermstory_crm_view_type",

  CITY_QUEST_SHOWN = "nx_fermstory_city_quest",

  GLOBAL_SEARCH_TEXT = "nx_fermstory_gsearch_text",

  LOCK_SCREEN = "nx_fermstory_lock_screen",

  SETTING_ADMIN_SIDEBAR = "nx_fermstory_sidebar",

  SETTINGS_HOME_IMGS = "nx_fermstory_home_images",
  SETTINGS_CONTACTS = "nx_fermstory_contacts",
  SETTINGS_HSS = "nx_fermstory_hss",
  SETTINGS_TRANS = "nx_fermstory_trans",
  SETTINGS_TRANS_ST = "nx_fermstory_trans_st",
  SETTINGS_FOOTER_DATA = "nx_fermstory_footer_data",
  SETTINGS_ABOUT = "nx_fermstory_about_data",
  SETTINGS_SEO = "nx_fermstory_seo_config",
  SETTINGS_SERVICES = "nx_fermstory_services_config",

  PROJECTS = "nx_fermstory_projects",

  LOCALE = "nx_pb_locale",
  COUNTRY = "nx_pb_country"
}

export const TRANS_LANGS = [
  {
    "name": "Русский",
    "nameEn": "Russian",
    "items": [
      { "name": "head_home", "value": "Главная", "value_en": "Home" },
      { "name": "head_studios", "value": "Творческие студии", "value_en": "Creative Studios" },
      { "name": "head_partners", "value": "Стать партнером", "value_en": "Become a partner" },
      { "name": "head_about", "value": "О сервисе", "value_en": "About us" },
      { "name": "head_cabinet", "value": "Личный кабинет", "value_en": "Personal Cabinet" },
      { "name": "head_login", "value": "Войти", "value_en": "Sing In" },
      { "name": "head_signup", "value": "Регистрация", "value_en": "Sign Up" },

      { "name": "select_country", "value": "Выберите страну", "value_en": "Select country" },
      { "name": "select_option", "value": "Выбрать", "value_en": "Select" },
      
      { "name": "footer_clients", "value": "ДЛЯ КЛИЕНТОВ", "value_en": "FOR CLIENTS" },
      { "name": "footer_studios", "value": "ДЛЯ СТУДИЙ", "value_en": "FOR PARTNERS" },
      { "name": "footer_c_blog", "value": "Блог", "value_en": "Blog" },
      { "name": "footer_c_podcasts", "value": "Новые подкасты", "value_en": "New Podcasts" },
      { "name": "footer_c_agree", "value": "Пользовательское соглашение", "value_en": "User agreement" },
      { "name": "footer_s_partner", "value": "Стать партнером", "value_en": "Become a partner" },
      { "name": "footer_s_cabinet", "value": "Личный кабинет партнера", "value_en": "Partner Cabinet" },
      { "name": "footer_s_agree", "value": "Партнерское соглашение", "value_en": "Partner agreement" },
      { "name": "footer_copys", "value": "© 2024-2025 «ПОДКАСТБЕРИ»", "value_en": "© 2024-2025 «PODCASTBERY»" },
      { "name": "footer_cities", "value": "Города присутствия", "value_en": "Cities of presence" },
      { "name": "footer_policy", "value": "Политика конфиденциальности", "value_en": "Privacy Policy" },

      { "name": "home_title", "value": "Откройте мир подкастов", "value_en": "Discover the world of podcasts" },
      { "name": "home_subtitle", "value": "Найдите студию для записи подкастов, звукозаписи, видеопродакшена", "value_en": "Find a studio for podcast recording, audio recording, video production" },
      { "name": "home_text", "value": "Уникальный агрегатор подкаст студий, где каждая запись становится произведением искусства. Мы предлагаем вам удобную платформу, где вы можете найти, сравнить и забронировать студию, идеально подходящую для ваших творческих идей.", "value_en": "A unique podcast studio aggregator where every recording becomes a work of art. We offer you a convenient platform where you can find, compare and book a studio that is perfect for your creative ideas." },
      { "name": "home_action", "value": "Найти студию", "value_en": "Find a studio" },
      
      { "name": "home_why", "value": "Почему выбирают нас?", "value_en": "Why choose us?" },
      { "name": "home_why_b1", "value": "Выбирайте студию под свои цели", "value_en": "Choose a studio according to your goals" },
      { "name": "home_why_b1_txt", "value": "Каждая студия представлена с подробным описанием, отзывами и уникальными предложениями, что позволяет вам принимать обоснованное решение.", "value_en": "Each studio is presented with a detailed description, reviews and unique offers, allowing you to make an informed decision." },
      { "name": "home_why_b2", "value": "Автоматизированный процесс бронирования и отмены", "value_en": "Automated booking and cancellation process" },
      { "name": "home_why_b2_txt", "value": "Добавляйте студию в избранное и удобным способом повторно бронируйте площадку через историю заказов.", "value_en": "Add the studio to your favorites and conveniently re-book the venue through the order history." },
      { "name": "home_why_b3", "value": "Удобный поиск", "value_en": "Convenient search" },
      { "name": "home_why_b3_txt", "value": "Интуитивный интерфейс, где легко можно найти и забронировать нужную студию.", "value_en": "An intuitive interface where you can easily find and book the studio you need." },
      { "name": "home_why_b4", "value": "Разнообразие студий", "value_en": "Variety of studios" },
      { "name": "home_why_b4_txt", "value": "Мы предлагаем разнообразные варианты: от уютных домашних студий до профессионально оборудованных пространств с современными технологиями.", "value_en": "We offer a variety of options: from cozy home studios to professionally equipped spaces with modern technologies." },
      { "name": "home_why_b5", "value": "Будь в курсе", "value_en": "Stay informed" },
      { "name": "home_why_b5_txt", "value": "Рассказываем про новые подкаст-проекты в индустрии.", "value_en": "We talk about new podcast projects in the industry." },
      
      { "name": "home_why_txt", "value": "Не позволяйте идеям оставаться несозданными: забронируйте студию без лишних хлопот и погрузитесь в мир звукового творчества. Начните ваше путешествие в мир подкастов уже сегодня и дайте волю своим мыслям, рассказам и идеям!", "value_en": "Don't let ideas go uncreated: book a studio without the hassle and dive into the world of audio creativity. Start your podcasting journey today and let your thoughts, stories and ideas flow!" },
      
      { "name": "home_cats", "value": "Выберите направление", "value_en": "Choose a direction" },
      { "name": "home_cats_all", "value": "Все направления", "value_en": "All directions" },
      
      { "name": "home_partners", "value": "Наши партнеры", "value_en": "Our partners" },
      
      { "name": "studios_filters", "value": "Фильтры", "value_en": "Filters" },
      { "name": "studios_sort", "value": "Сортировка", "value_en": "Sorting" },
      { "name": "studios_srch", "value": "Подкастбери", "value_en": "Podcastbery" },
      { "name": "studios_list", "value": "Списком", "value_en": "List" },
      { "name": "studios_onmap", "value": "На карте", "value_en": "On Map" },
      
      { "name": "studios_srch_city", "value": "Город", "value_en": "City" },
      { "name": "studios_srch_metro", "value": "Метро", "value_en": "Metro" },
      { "name": "studios_srch_cats", "value": "Направление", "value_en": "Direction" },
      { "name": "studios_srch_date", "value": "Дата", "value_en": "Date" },
      { "name": "studios_srch_all", "value": "Все", "value_en": "All" },
      { "name": "studios_srch_action", "value": "Найти", "value_en": "Find" },
      { "name": "studios_srch_selcat", "value": "Выберите направление", "value_en": "Select direction" },
      { "name": "studios_srch_selcity", "value": "Выберите город", "value_en": "Select city" },
      { "name": "studios_srch_selmetro", "value": "Выберите метро", "value_en": "Select metro" },
      { "name": "studios_srch_seldate", "value": "Выберите дату", "value_en": "Select date" },
      { "name": "studios_srch_deldate", "value": "Очистить", "value_en": "Clear" },
      
      { "name": "studios_search", "value": "Поиск", "value_en": "Search" },
      { "name": "studios_srch_not", "value": "Не указывать", "value_en": "Do not specify" },
      
      { "name": "studios_notfound", "value": "К сожалению, мы не нашли студии по заданным параметрам...", "value_en": "Unfortunately, we did not find any studios matching your criteria..." },
      { "name": "studios_others", "value": "Другие студии в этом городе", "value_en": "Other studios in this city" },
      
      { "name": "studios_comeback", "value": "Сколько человек вернулось в студию", "value_en": "How many people returned to the studio" },
      
      { "name": "sort", "value": "Сортировка", "value_en": "Sorting" },
      { "name": "sort_def", "value": "По умолчанию", "value_en": "By default" },
      { "name": "sort_min", "value": "Дешевле", "value_en": "Cheaper" },
      { "name": "sort_max", "value": "Дороже", "value_en": "More expensive" },
      { "name": "sort_rate", "value": "По рейтингу", "value_en": "By rating" },
      { "name": "sort_accept", "value": "Применить", "value_en": "Apply" },
      
      { "name": "filters", "value": "Фильтры", "value_en": "Filters" },
      { "name": "filters_accept", "value": "Применить", "value_en": "Apply" },
      { "name": "filters_facils", "value": "Удобства", "value_en": "Facilities" },
      { "name": "filters_servs", "value": "Сервис", "value_en": "Services" },
      
      { "name": "studio_time", "value": "ВРЕМЯ РАБОТЫ", "value_en": "WORKING HOURS" },
      { "name": "studio_time_o", "value": "будни", "value_en": "weekdays" },
      { "name": "studio_time_s", "value": "выходные", "value_en": "weekend" },
      { "name": "studio_desc", "value": "Описание", "value_en": "Description" },
      { "name": "studio_servs", "value": "Услуги", "value_en": "Services" },
      { "name": "studio_revs", "value": "Отзывы", "value_en": "Reviews" },
      { "name": "studio_book", "value": "Забронировать", "value_en": "Book now" },
      { "name": "studio_not_info", "value": "Студия пока что не разместила о себе информацию", "value_en": "The studio has not yet posted any information about itself" },
      { "name": "studio_tools", "value": "Оборудование", "value_en": "Equipment" },
      { "name": "studio_not_servs", "value": "Услуги отсутствуют", "value_en": "Services are not available" },
      { "name": "studio_not_srv", "value": "Студия пока что не разместила свои услуги", "value_en": "The studio has not yet posted its services" },
      { "name": "studio_mins", "value": "минут", "value_en": "mins" },
      { "name": "studio_book_online", "value": "Онлайн бронирование", "value_en": "Online booking" },
      { "name": "studio_not_tt", "value": "Студия пока что не разместила актуальное расписание", "value_en": "The studio has not yet posted an up-to-date schedule" },
      { "name": "studio_contacts", "value": "Контакты", "value_en": "Contacts" },
      { "name": "studio_addr", "value": "Адрес", "value_en": "Address" },
      { "name": "studio_phone", "value": "Телефон", "value_en": "Phone number" },
      { "name": "studio_web", "value": "Веб-сайт", "value_en": "Website" },
      { "name": "studio_empty", "value": "Тут пока что пусто...", "value_en": "It's empty here for now..." },
      { "name": "studio_info", "value": "Информация", "value_en": "Information" },
      { "name": "studio_pls", "value": "Пожалуйста, скажите студии, что вы нашли их через сервис podcastbery.ru. Это очень важно для нас, благодарим.", "value_en": "Please tell the studio that you found them through podcastbery.ru. This is very important to us, thank you." },
      { "name": "studio_open", "value": "Данные об этой компании были взяты с открытых источников", "value_en": "Data about this company was taken from open sources" },
      { "name": "studio_rev", "value": "Отзыв", "value_en": "Review" },
      { "name": "studio_more", "value": "Подробнее", "value_en": "More" },
      
      { "name": "account_title", "value": "Мой аккаунт", "value_en": "My account" },
      { "name": "account_tab_orders", "value": "Заказы", "value_en": "Orders" },
      { "name": "account_tab_settings", "value": "Настройки", "value_en": "Settings" },
      { "name": "account_tab_favs", "value": "Избранное", "value_en": "Favorites" },
      { "name": "account_o_empty", "value": "Список пуст. Оформите свой первый заказ", "value_en": "The list is empty. Place your first order" },
      { "name": "account_o_empty_a", "value": "на студию", "value_en": "for the studio" },
      { "name": "account_personal_info", "value": "Личные данные", "value_en": "Personal data" },
      
      { "name": "about_desc", "value": "Это новая цифровая экосистема в подкаст-индустрии", "value_en": "This is a new digital ecosystem in the podcast industry" },
      { "name": "about_dsc1", "value": "Мы разрабатываем уникальные решения в медиа-сегменте, которые помогают вам воплотить в жизнь все ваши идеи и амбиции. Наша команда стремится создать продукты, которые помогут раскрыть творческий потенциал каждого подкастера.", "value_en": "We develop unique solutions in the media segment that help you realize all your ideas and ambitions. Our team strives to create products that will help to reveal the creative potential of each podcaster." },
      { "name": "about_dsc2", "value": "Кроме того, мы активно развиваем сообщество, в котором участники могут делиться опытом, находить единомышленников и обсуждать лучшие практики.", "value_en": "In addition, we are actively developing a community where participants can share experiences, find like-minded people and discuss best practices." },
      { "name": "about_dsc3", "value": "Наша цель", "value_en": "Our goal" },
      { "name": "about_dsc31", "value": "не просто улучшить качество сервиса в подкаст-индустрии, но и создать атмосферу поддержки и сотрудничества между создателями контента и студиями.", "value_en": "not only to improve the quality of service in the podcast industry, but also to create an atmosphere of support and cooperation between content creators and studios." },
      { "name": "about_pers1_name", "value": "Владислав Казанцев", "value_en": "Vladislav Kazantsev" },
      { "name": "about_pers1_spec", "value": "Основатель сервиса", "value_en": "Founder of the service" },
      { "name": "about_pers2_name", "value": "Михаил Алешин", "value_en": "Mikhail Aleshin" },
      { "name": "about_pers2_spec", "value": "Технический директор", "value_en": "Technical Director" },
      { "name": "about_pers3_name", "value": "Максим Фурдилов", "value_en": "Maxim Furdilov" },
      { "name": "about_pers3_spec", "value": "Директор по маркетингу", "value_en": "Marketing Director" },
      
      { "name": "becomepart_desc", "value": "Сервис для поиска и бронирования творческих площадок", "value_en": "Service for searching and booking creative spaces" },
      { "name": "becomepart_desc2", "value": "Наша платформа обеспечивает удобный и интуитивно понятный интерфейс, где легко выбирать студию по нужным критериям: местоположение, услуга, оборудование и стоимость.", "value_en": "Our platform provides a convenient and intuitive interface, where it is easy to choose a studio based on the desired criteria: location, service, equipment and cost." },
      { "name": "becomepart_action1", "value": "Перейти к управлению", "value_en": "Go to management" },
      { "name": "becomepart_action2", "value": "Оставить заявку", "value_en": "Leave a request" },
      { "name": "becomepart_action3", "value": "Начать процесс", "value_en": "Start the process" },
      { "name": "becomepart_steps", "value": "Этапы сотрудничества", "value_en": "Stages of cooperation" },
      { "name": "becomepart_st1_ttl", "value": "Заполните", "value_en": "Fill in and submit" },
      { "name": "becomepart_st1_desc", "value": "и отправьте заявку", "value_en": "your application" },
      { "name": "becomepart_st2_ttl", "value": "Наш менеджер", "value_en": "Our manager" },
      { "name": "becomepart_st2_desc", "value": "свяжется с вами", "value_en": "will contact you" },
      { "name": "becomepart_st3_ttl", "value": "Подпишите", "value_en": "Sign" },
      { "name": "becomepart_st3_desc", "value": "договор", "value_en": "contract" },
      { "name": "becomepart_st4_ttl", "value": "Зарегистрируйтесь", "value_en": "Register" },
      { "name": "becomepart_st4_desc", "value": "на сайте и заполните личный кабинет студии", "value_en": "on the website and fill out the studio's personal account" },
      { "name": "becomepart_st5_ttl", "value": "Получайте заказы!", "value_en": "Receive orders!" },
      { "name": "becomepart_st5_desc", "value": "", "value_en": "" },
      
      { "name": "becomepart_super", "value": "Сотрудничество с нашим сервисом открывает широкий спектр преимуществ", "value_en": "Cooperation with our service opens up a wide range of advantages" },
      { "name": "becomepart_super1", "value": "Привлечение новых клиентов, повышение лояльности текущих. Ежемесячно наш сервис посещают 1000+ человек", "value_en": "Attracting new customers, increasing loyalty of current ones. Every month our service is visited by 1000+ people" },
      { "name": "becomepart_super2", "value": "Удобная CRM-система с аналитикой по количеству заказов, онлайн-бронирований, выручке для владельца студии и пуш-уведомлений", "value_en": "Convenient CRM system with analytics on the number of orders, online bookings, revenue for the studio owner and push notifications" },
      { "name": "becomepart_super3", "value": "Создание уникальных привлекательных предложений для клиентов, система промокодов", "value_en": "Creation of unique attractive offers for clients, promo code system" },
      { "name": "becomepart_super4", "value": "Сокращение расходов на продвижение. Принцип: «маркетинг без бюджета»", "value_en": "Reducing promotion costs. The principle: 'marketing without a budget'" },
      { "name": "becomepart_super5", "value": "Повышение конкурентоспособности. Услуги заметно выделяются среди аналогов. Действует система рейтинга, отзывов и других метрик", "value_en": "Increased competitiveness. Services stand out from their peers. There is a system of ratings, reviews and other metrics" },
      { "name": "becomepart_tariffs", "value": "Наши тарифы", "value_en": "Our tariffs" },
      { "name": "becomepart_tariffs1", "value": "Уникальная CRM система управления студией", "value_en": "Unique CRM studio management system" },
      { "name": "becomepart_tariffs2", "value": "Постоянный поток новых клиентов", "value_en": "Constant flow of new clients" },
      { "name": "becomepart_tariffs3", "value": "Удобный виджет бронирования студии", "value_en": "Convenient studio booking widget" },
      { "name": "becomepart_tariffs4", "value": "Модерация контента", "value_en": "Content moderation" },
      { "name": "becomepart_tariffs5", "value": "Консультация со специалистом по ценообразованию", "value_en": "Consultation with a pricing specialist" },
      { "name": "becomepart_tariffs6", "value": "Настройка имеющихся скидок, акций, промокодов", "value_en": "Setting up existing discounts, promotions, promo codes" },
      { "name": "becomepart_free", "value": "В каждом тарифе действует пробный период 1 месяц", "value_en": "Each tariff has a trial period of 1 month" },
      { "name": "becomepart_you_partner", "value": "Вы наш партнер", "value_en": "You are our partner" },
      { "name": "becomepart_you_partner1", "value": "По кнопке ниже у Вас есть возможность перейти к управлению своей студией", "value_en": "By clicking the button below you have the opportunity to proceed to managing your studio" },
      { "name": "becomepart_you_sender", "value": "Вы подали заявку", "value_en": "You have submitted your application" },
      { "name": "becomepart_you_sender1", "value": "Ожидайте, наш менеджер свяжется с Вами в ближайшее время.", "value_en": "Please wait, our manager will contact you shortly." },
      { "name": "becomepart_you_sender_action", "value": "Открыть презентацию", "value_en": "Open presentation" },
      { "name": "becomepart_send", "value": "Подать заявку", "value_en": "Submit an application" },
      { "name": "becomepart_send_desc", "value": "Заполните форму ниже, отправьте нам свои данные и наш менеджер свяжется с Вами в ближайшее время.", "value_en": "Fill out the form below, send us your details and our manager will contact you shortly." },
      { "name": "becomepart_partners", "value": "C нами уже работают", "value_en": "They are already working with us" },
      
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
    ]
  },
  {
    "name": "Анлглийский",
    "nameEn": "English",
    "items": [
      { "name": "head_home", "value": "Главная", "value_en": "Home" }
    ]
  }
]

export const ruPickerLang = {
  today: "Сегодня",
  now: "Сегодня",
  ok: "Выбрать",
  backToToday: "Вернуться к сегодня",
  timeSelect: "Выбрать время",
  dateSelect: "Выбрать дату",
  clear: "Сбросить",
  month: "Месяц",
  year: "Год",
  previousMonth: "Предыдущий месяц",
  nextMonth: "Следующий месяц",
  monthSelect: "Выбрать месяц",
  yearSelect: "Выбрать год",
  decadeSelect: "Выбрать декаду",
  previousYear: "Предыдущий год",
  nextYear: "Следующий год",
  previousDecade: "Предыдущая декада",
  nextDecade: "Следующая декада",
  previousCentury: "Предыдущий век",
  nextCentury: "Следующий век",

  yearFormat: "YYYY",
  dateFormat: "M/D/YYYY",
  dayFormat: "D",
  dateTimeFormat: "M/D/YYYY HH:mm:ss",
  monthFormat: "MMMM",

  shortMonths: ["Янв", "Фев", "Мар", "Апр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек"],
  shortWeekDays: ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"]
}